<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'IntroCurated'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col cols="12" class="text-center pt-5">
					<h4 class="text-h4 text-center">you're all set</h4>
					<v-card-subtitle>choose what you'd like to do next</v-card-subtitle>
				</v-col>
			</v-row>
			<v-row justify="center" class="fill-height">
				<v-col cols="12" md="6">
					<v-card dark color="accent">
						<div class="d-flex flex-no-wrap justify-space-between align-center">
							<div>
								<v-card-title class="text-h5">first memory</v-card-title>
								<v-card-actions>
									<v-btn color="secondary" rounded :to="{name: 'NewMemory'}">create</v-btn>
								</v-card-actions>
							</div>

							<v-avatar class="d-none d-md-block ma-3" size="105">
								<img src="../../assets/toon_5.png" width="80"></img>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" class="fill-height">
					<v-card dark color="accent" class="h-100">
						<div class="d-flex flex-no-wrap justify-space-between align-center">
							<div>
								<v-card-title class="text-h5">all memories</v-card-title>
								<v-card-actions>
									<v-btn color="secondary" exact rounded :to="{name: 'Home'}">view</v-btn>
								</v-card-actions>
							</div>

							<v-avatar class="d-none d-md-block ma-3" size="105">
								<v-img src="../../assets/placeholder.png" width="100"></v-img>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12">
					<r-platform-links></r-platform-links>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "Finished",
	metaInfo: {
		title: 'Finished',
	},
	computed: {
		user: sync('user/user')
	},
	mounted() {
		this.updateUser()
	},
	methods: {
		updateUser() {
			this.$api.users.update({user: {onboarding: true}})
				.then(response => this.user = response.data)
				.catch(error => console.log(error))
		}
	}
}
</script>

<style scoped>

</style>